(
    /**
     * Self-executing function to determine if lodash is loaded and if so, run it in no-conflict mode.
     * @param window
     * @param document
     * @param undefined
     */
    function (window, document, undefined) {
        if (window.hasOwnProperty('_')) {
            const maybeLodash = window._,
                lodashScript = document.getElementById('lodashScript');

            if (lodashScript !== null && typeof maybeLodash === 'function' && typeof maybeLodash.hasOwnProperty === 'function') {
                const lodashPathname = (new URL(lodashScript.src)).pathname,
                    version = lodashPathname.split('/')
                        .reduce(
                            (rtnValue, currentValue) => {
                                if (currentValue.indexOf('lodash@') > -1) {
                                    rtnValue = currentValue.split('@').pop();
                                }

                                return rtnValue;
                            },
                            ''
                        );
                if (maybeLodash.hasOwnProperty('VERSION') && maybeLodash.VERSION === version) {
                    window.lodash = maybeLodash.noConflict();
                }
            }
        }
    }
)(window, document);
